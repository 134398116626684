<template>
  <div v-if="visible">
    <b-card title="" border-variant="secondary">
      <b-row>
        <b-col cols="12" class="mb-2">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span
                >Diligencia los campos solicitados y haz click en Aceptar</span
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-table
        striped
        hover
        responsive
        id="tableSolicitud"
        :items="items"
        :fields="fields"
        striped
        small
        table-variant="secondary"
      >
        <template #cell(vlAsegurado)="row">
          <b-form-input
            class="custom-control-secondary"
            name="check-button"
            v-model="row.item.vlAsegurado"
          >
          </b-form-input>
        </template>
        <template #cell(vlDeclarado)="row">
          <b-form-input
            class="custom-control-secondary"
            name="check-button"
            v-model="row.item.vlDeclarado"
          >
          </b-form-input>
        </template>

        <template #cell(Tracking)="data" size="sm">
          <b-badge variant="success">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(HAWB)="data" size="sm">
          <b-badge variant="info">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(Servicio)="data" size="sm">
          <b-badge variant="warning">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
      <br />
      <b-row>
        <b-col cols="12" class="mb-2">
          <b-alert variant="info" show>
            <div class="alert-body">
              <span
                >Recuerda que el valor estimado peude estar sujeto a cambios.</span
              >
            </div>
          </b-alert>
          <br />
        </b-col>
      </b-row>
      <validation-observer ref="registerRules">
        <b-row>
            <b-col sm="12" md="6">
              <b-form-group
                label-for="i-destinatario"
                label="Destinatario"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Destinatario"
                  rules="required"
                >
                  <b-form-select
                    id="i-destinatario"
                    placeholder=""
                    v-model="destinatarioId"
                    :options="optionsDestinatarios"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group
                label-for="i-medioPago"
                label="Medio de Pago"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Medio_de_pago"
                  rules="required"
                >
                  <b-form-select
                    id="i-medioPago"
                    placeholder=""
                    v-model="medioPago"
                    :options="optionsMediosPago"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group
                label-for="i-formaPago"
                label="Forma de Pago"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Forma_de_pago"
                  rules="required"
                >
                  <b-form-select
                    id="i-formaPago"
                    placeholder=""
                    v-model="formaPago"
                    :options="optionsFormasPago"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group
                label-for="i-observaciones"
                label="Observaciones"
              >
                <b-form-textarea
                  id="i-observaciones"
                  placeholder=""
                  v-model="observacion"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="success"
                class="mt-2"
                @click="onFormSolicitarButtonClick()"
              >
                Aceptar
              </b-button>
              <b-button
                variant="danger"
                class="mt-2"
                @click="closeComponent()"
                style="margin-left:5px"
              >
                Cerrar
              </b-button>
            </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BButton,
    BTable,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    items: Array,
    optionsDestinatarios: Array,
    optionsMediosPago: Array,
    optionsFormasPago: Array,
  },
  data() {
    return {
      visible : true,
      errors: [],
      fields: [
        { key: "vlAsegurado", label: "Valor Asegurado" },
        { key: "vlDeclarado", label: "Valor Declarado" },
        { key: "FRecibido", label: "Fecha Recibido" },
        "Tracking",
        "Estado",
        { key: "PesoLbs", label: "Peso(Lbs)" },
        { key: "PesoKgs", label: "Peso(Kgs)" },
        "Contenido",
        "Tienda",
        "HAWB",
        "Servicio",
        { key: "OrdenCompra", label: "Orden de Compra" },
        "Imagen",
      ],
      destinatarioId: "",
      medioPago: "",
      formaPago: "",
      observacion: "",
    };
  },
  created() {},
  computed: {
    paquetes() {
      let buildArray = [];
      this.items.forEach((element) => {
        let item = {
          id: element.original.id,
          valor_asegurado: element.vlAsegurado,
          valor_declarado: element.vlDeclarado,
        };
        buildArray.push(item);
      });
      return JSON.stringify(buildArray);
    },
  },
  methods: {
    closeComponent() {
      this.$emit('close');
    },
    solicitar() {
      this.$http
        .post(
          `api/casillero/despachos/preliquidaciones`,
          {
            paquetes: this.paquetes,
            pago_id: this.medioPago,
            forma_de_pago_id: this.formaPago,
            destinatario_id: this.destinatarioId,
            observacion: this.observacion,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          alert(response.data.msg);
          this.items = [];
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    validationFormSolicitar() {
      return new Promise((resolve, reject) => {
        this.$refs.registerRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFormSolicitarButtonClick() {
      let packages = JSON.parse(this.paquetes).length;
      if (packages <= 0) {
        this.$swal({
          title: "Solicitar despacho",
          text: "Debe seleccionar al menos un paquete",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.validationFormSolicitar().then((validForm) => {
          if (validForm) {
            this.solicitar();
          }
        });
      }
    },
  },
};
</script>