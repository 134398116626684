<template>
  <div>
    <b-card title="">
      <b-row>
        <b-col cols="12" class="mb-2">
          <h2 class="mb-0">Solicitar Despachos</h2>
          <br />
          <h4>{{ $store.getters["clients/getFullName"] }}</h4>
          <h5>No Casillero: {{ $store.getters["clients/getCasillero"] }}</h5>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="showSolicitar">
      <solicitar
        :items="paquetesSolicitud"
        :optionsDestinatarios="optionsDestinatarios"
        :optionsMediosPago="optionsMediosPago"
        :optionsFormasPago="optionsFormasPago"
        @close="showSolicitar = false"
      ></solicitar>
    </div>
    <b-card title="Paquetes Disponibles">
      <!--<b-table striped hover :items="items" :fields="fields"></b-table>-->
      <div>
        <b-row>
          <b-col sm="12" md="8">
            <b-button
              v-if="!showSolicitar"
              variant="primary"
              @click="validatePackages()"
            >
              Solicitar
            </b-button>
          </b-col>
          <b-col sm="12" md="2">
            <b-form-group
              label-for="i-perPage"
              label="Items por página"
              label-cols="7"
            >
              <b-form-input
                id="i-perPage"
                type="number"
                placeholder=""
                v-model="perPage"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="tableDespachos"
              align="right"
            ></b-pagination>
            <!-- <p class="mt-1 text-center">Página actual: {{ currentPage }}</p> -->
          </b-col>
        </b-row>
        <b-table
          striped
          hover
          responsive
          id="tableDespachos"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(consolidar)="row">
            <b-form-checkbox
              checked="false"
              name="check-button"
              switch
              @change="onCheckBoxChange(row, $event)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </template>

          <template #cell(Tracking)="data" size="sm">
            <b-badge variant="success">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(Hawb)="data" size="sm">
            <b-badge variant="info">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(Servicio)="data" size="sm">
            <b-badge variant="warning">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
        <br />
      </div>
    </b-card>
    <b-card
      title="Preliquidaciones realizadas"
      v-if="showSecondTable"
    >
      <b-row>
        <b-col sm="12" md="4">
          <b-form-group label-for="i-idSolicitud" label="Id Solicitud">
            <b-form-input
              id="i-idSolicitud"
              type="text"
              placeholder=""
              v-model="idSolicitud"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4">
          <b-form-group label-for="i-desde" label="Desde">
            <b-form-input
              id="i-desde"
              type="date"
              placeholder=""
              v-model="desde"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4">
          <b-form-group label-for="i-hasta" label="Hasta">
            <b-form-input
              id="i-hasta"
              type="date"
              placeholder=""
              v-model="hasta"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="4">
          <b-form-group label-for="i-medioPago" label="Medio de Pago">
            <b-form-select
              id="i-medioPago"
              placeholder=""
              v-model="medioPago"
              :options="optionsMediosPago"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4">
          <b-form-group label-for="i-formaPago" label="Formas de Pago">
            <b-form-select
              id="i-formaPago"
              placeholder=""
              v-model="formaPago"
              :options="optionsFormasPago"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4">
          <b-form-group label-for="i-destinatario" label="Destinatario">
            <b-form-select
              id="i-destinatario"
              placeholder=""
              v-model="destinatarioId"
              :options="optionsDestinatarios"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
              variant="primary"
              class="mt-2"
              @click="getSolicitudesRealizadas()"
          >
            Buscar
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
          <b-col sm="12" md="6">
            <b-form-group
              label-for="i-perPage"
              label="Items por página"
              label-cols="7"
            >
              <b-form-input
                id="i-perPage"
                type="number"
                placeholder=""
                v-model="perPage"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-pagination
              v-model="currentPageSol"
              :total-rows="rowsSol"
              :per-page="perPageSol"
              aria-controls="tableDespachosSol"
              align="right"
            ></b-pagination>
            <!-- <p class="mt-1 text-center">Página actual: {{ currentPage }}</p> -->
          </b-col>
        </b-row>
        <b-table
            striped
            hover
            responsive
          id="tableDespachosSol"
          :items="itemsSol"
          :fields="fieldsSol"
          :per-page="perPageSol"
          :current-page="currentPageSol"
        >
          <template #cell(Opciones)="row">
            <b-row>
              <b-col>
                <b-button
                  variant="success"
                  size="sm"
                  @click="getPdf(row)"
                >
                  <b-icon icon="printer-fill" aria-hidden="true"></b-icon>
                </b-button>
              </b-col>
              <b-col>
                <b-button variant="warning" size="sm" @click="onFileClick(row)">
                  <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                </b-button>
              </b-col>
            </b-row>
          </template>
          <template #cell(SoportesPago)="row">
            <b-row>
              <b-col v-for="item in row.SoportesPago" :key="item.id">
                <b-button
                  variant="relief-secondary"
                  size="sm"
                  @click="onSoportePagoClick(row)"
                >
                  <b-icon icon="paperclip" aria-hidden="true"></b-icon>
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
        <br />
    </b-card>
    <b-modal
      v-model="showModalVisorPdf"
      ok-title="Descargar"
      cancel-title="Cerrar"
      cancel-variant="danger"
      id="modalVisorPdf"
      title="Imprimir Pdf"
      size="lg"
      @ok="downloadPdf"
    >
      <visor :src="pdfRoute"></visor>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import solicitar from "@/views/despachos/solicitar.vue";
import adjuntar from "@/views/despachos/adjuntar.vue";
import visor from "@/views/despachos/visor.vue";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BButton,
  BTable,
  BPagination,
  BIcon,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    BIcon,
    vSelect,
    solicitar,
    adjuntar,
    visor,
  },
  data() {
    return {
      pdfRoute: "",
      showModalVisorPdf: false,
      showSecondTable: true,
      perPage: 10,
      currentPage: 1,
      perPageSol: 10,
      currentPageSol: 1,
      solicitudId: 1,
      formSearchData: {
        strSearch: "",
      },
      fields: [
        { key: "consolidar", label: "Consolidar" },
        { key: "id", label: "#" },
        "Imagen",
        "FechaRecibido",
        "Tracking",
        "Estado",
        "Hawb",
        "Contenido",
        "Tienda",
        "Servicio",
        "PesoLb",
        "PesoKg",
        "OrdenCompra",
        "Destinatario",
      ],
      items: [],
      fieldsSol: [
        "Opciones",
        "Num",
        "Fecha",
        "Destinatario",
        "Guia",
        "GuiasAgrupadas",
        "NotifEmail",
        "MediosPago",
        "TRM",
        "Valor",
        "SoportesPago",
        "Conciliada",
        "Autorizada",
        "Observacion",
      ],
      itemsSol: [],
      idSolicitud: "",
      desde: "",
      hasta: "",
      medioPago: "",
      optionsMediosPago: [],
      destinatarioId: "",
      optionsDestinatarios: [],
      formaPago: "",
      optionsFormasPago: [],
      paquetesSolicitud: [],
      showSolicitar: false,
      showAdjuntar: false,
    };
  },
  created() {},
  computed: {
    rows() {
      return this.items.length;
    },
    rowsSol() {
      return this.itemsSol.length;
    },
  },
  methods: {
    onCheckBoxChange(row, event) {
      let selectedRow = row.item;
      if (event == true) {
        let paquete = {
          original: selectedRow.original,
          id: selectedRow.id,
          vlAsegurado: selectedRow.valor_asegurado,
          vlDeclarado: selectedRow.valor_declarado,
          FRecibido: selectedRow.original.fecha_recibido,
          Tracking: selectedRow.original.tracking,
          Estado: selectedRow.original.estado,
          PesoLbs: selectedRow.original.peso_lb,
          PesoKgs: selectedRow.original.peso_kg,
          Contenido: selectedRow.original.contenido,
          Tienda: selectedRow.original.tienda,
          HAWB: selectedRow.original.hawb,
          Servicio: selectedRow.original.servicio,
          OrdenCompra: selectedRow.original.orden_de_compra,
          Imagen: selectedRow.original.imagen,
        };
        this.paquetesSolicitud.push(paquete);
      } else {
        let pos = 0;
        for (let index = 0; index < this.paquetesSolicitud.length; index++) {
          if (this.paquetesSolicitud[index].id == selectedRow.id) {
            pos = index;
          }
        }
        this.paquetesSolicitud.splice(pos, 1);
      }
    },
    loadDespachos() {
      const str = `Bearer ${this.$session.get("userData").access_token}`;
      this.$http
        .post(
          `api/casillero/despachos/preliquidaciones/paqsdisponibles?page=${this.currentPage}`,
          {
            str_busqueda: this.formSearchData.strSearch,
            pageSize: this.perPage,
          },
          {
            headers: { Authorization: str },
          }
        )
        .then((response) => {
          //console.log(response.data);
          this.formatDataforTable(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataforTable(dataFromService) {
      this.items = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        itemFormated = {
          original: itemFromService,
          consolidar: itemFromService.consolidar,
          id: itemFromService.id,
          Imagen: itemFromService.imagen,
          FechaRecibido: itemFromService.fecha_recibido,
          Tracking: itemFromService.tracking,
          Estado: itemFromService.estado,
          Hawb: itemFromService.hawb,
          Contenido: itemFromService.contenido,
          Tienda: itemFromService.tienda,
          Servicio: itemFromService.servicio,
          PesoLb: itemFromService.peso_lb,
          PesoKg: itemFromService.peso_kg,
          OrdenCompra: itemFromService.orden_de_compra,
          Destinatario: itemFromService.destinatario,
          valor_asegurado: itemFromService.valor_asegurado,
          valor_declarado: itemFromService.valor_declarado,
        };
        formatedData.push(itemFormated);
      });
      this.items = formatedData;
      //console.log(this.items);
    },
    getSolicitudesRealizadas() {
      this.$http
        .post(
          `api/casillero/despachos/preliquidaciones/solrealizadas`,
          {
            pageSize: this.perPageSol,
            preliquidacion_id: this.idSolicitud,
            fechaDesde: this.desde,
            fechaHasta: this.hasta,
            medio_de_pago_id: this.medioPago,
            forma_de_pago_id: this.formaPago,
            destinatario_id: this.destinatarioId,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.formatDataRealizadas(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataRealizadas(dataFromService) {
      this.itemsSol = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        let paquetes = ``;
        let paqs_agrupados = ``;
        itemFromService.paquetes.forEach((paquete) => {
          paquetes = `${paquetes}\n${paquete.hawb}`;
        });
        itemFromService.paqs_agrupados.forEach((agrupado) => {
          paqs_agrupados = `${paqs_agrupados}\n${agrupado.hawb}`;
        });
        itemFormated = {
          original: itemFromService,
          Num: itemFromService.id,
          Fecha: itemFromService.created_at,
          OrdenCompra: itemFromService.orden_de_compra,
          Destinatario: itemFromService.destinatario.nombre_completo,
          NotifEmail: itemFromService.notificacion_email,
          TRM: itemFromService.trm_valor,
          Valor: itemFromService.valor,
          Guia: paquetes,
          GuiasAgrupadas: paqs_agrupados,
          NotifEmail: itemFromService.notificacion_email
            ? `Si ${itemFromService.notificacion_email}`
            : `No`,
          MediosPago: `${itemFromService.medio_de_pago.descripcion}\ \n ${itemFromService.forma_de_pago.descripcion}`,
          Conciliada: itemFromService.estado_conciliacion == 2 ? `Si` : `No`,
          Autorizada: itemFromService.autorizada ? `Si` : `No`,
          //SoportesPago : itemFromService.soportes_de_pago,
          Observacion: itemFromService.observacion,
        };
        console.log(itemFormated);
        formatedData.push(itemFormated);
        this.itemsSol = formatedData;
      });
    },
    getInfoForm() {
      this.$http
        .get("api/casillero/despachos/preliquidaciones/infoform", {
          headers: {
            Authorization: `Bearer ${
              this.$session.get("userData").access_token
            }`,
          },
        })
        .then((response) => {
          //console.log(response);
          let destinatarios = response.data.datos.destinatarios;
          let formasPago = response.data.datos.formasDePago;
          let mediosPago = response.data.datos.mediosDePago;
          this.formatDestinatariosForSelect(destinatarios);
          this.formatMediosPagoForSelect(mediosPago);
          this.formatFormasPagoForSelect(formasPago);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDestinatariosForSelect(destinatarios) {
      let itemFormated = {};
      destinatarios.forEach((element) => {
        itemFormated = {
          value: element.id,
          text: element.nombre_completo,
        };
        this.optionsDestinatarios.push(itemFormated);
      });
    },
    formatMediosPagoForSelect(mediosPago) {
      let itemFormated = {};
      mediosPago.forEach((element) => {
        itemFormated = {
          value: element.id,
          text: element.descripcion,
        };
        this.optionsMediosPago.push(itemFormated);
      });
    },
    formatFormasPagoForSelect(formasPago) {
      let itemFormated = {};
      formasPago.forEach((element) => {
        itemFormated = {
          value: element.id,
          text: element.descripcion,
        };
        this.optionsFormasPago.push(itemFormated);
      });
    },
    onFileClick(row) {
      this.solicitudId = row.item.Num;
      console.log(row.item);
      this.$router.push({
        name: "preliquidaciones-adjuntar",
        params: {
          solicitudId: this.solicitudId,
        },
      });
    },
    getPdf(row) {
      this.solicitudId = row.item.Num;
      this.$http
        .get(
          `api/casillero/despachos/preliquidaciones/impresion-pdf/${this.solicitudId}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.getBase64(response.data).then((data) => {
            this.pdfRoute = data;
            this.showModalVisorPdf = true;
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    downloadPdf(bvModalEvt) {
      bvModalEvt.preventDefault();
      var link = document.createElement("a");
      link.innerHTML = "Download PDF file";
      link.download = `${this.solicitudId}.pdf`;
      link.href = this.pdfRoute;
      link.click();
    },
    validatePackages() {
      if(this.paquetesSolicitud.length <= 0){
        this.$swal({
          title: "Solicitar despacho",
          text: "Debes seleccionar al menos un paquete",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }else{
        this.showSolicitar = true;
      }
    }
  },

  beforeMount() {
    this.loadDespachos();
    this.getSolicitudesRealizadas();
    this.getInfoForm();
  },
};
</script>

<style>
</style>
